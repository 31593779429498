import { makeAutoObservable } from 'mobx';
import { ClientProvider } from '../../utils/apiClient/clientProvider';
import { notification } from 'antd';
import { ClientTariff } from '../../types/company/client-tariff';

class TariffState {
  loading = false;
  addedTariff?: ClientTariff;
  updatedResponse: any;

  constructor() {
    makeAutoObservable(this);
  }

  async create(companyId: string) {
    try {
      this.loading = true;
      this.updatedResponse = await ClientProvider.authorized.post(`api/payment/tariff`, {
        price: this.addedTariff?.price,
        perUserPrice: this.addedTariff?.perUserPrice,
        companyId,
      });
      this.loading = false;
    } catch (e) {
      console.log(e);
      this.loading = false;
      notification.error({
        message: (e as any).response?.data?.message || (e as any).message,
      });
    }
  }

  async delete(id: string) {
    try {
      this.loading = true;
      await ClientProvider.authorized.delete(`api/payment/tariff/${id}`);
      this.loading = false;
    } catch (e) {
      console.log(e);
      this.loading = false;
      notification.error({
        message: (e as any).response?.data?.message || (e as any).message,
      });
    }
  }
}

export const tariffState = new TariffState();
