import { observer } from 'mobx-react';
import { Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd';
import React, { useEffect } from 'react';
import { autorun } from 'mobx';
import { FieldData } from 'rc-field-form/lib/interface';
import styles from '../../view/index.module.css';
import { RectangleInput } from '../../../common/rectangle_input';
import moment from 'moment';
import { clientsListState } from '../../../../state/clients/clients-list.state';
import { clientState } from '../../../../state/clients/client.state';
import { LegalForm } from '../../../../types/company/client-full';

export const CompanyViewInfo = observer(() => {
  useEffect(() => {
    return autorun(() => {
      //
    });
  });

  const fields: FieldData[] = [
    'legalForm',
    'name',
    'establishedAt',
    'inn',
    'kpp',
    'okpo',
    'okved',
    'ogrn',
  ].map((e) => ({
    name: e,
    value: (clientState.editedClient as any)[e],
  }));

  return (
    <>
      <p>
        <Typography.Text className={styles.titleText}>Основная информация</Typography.Text>
      </p>
      <Form style={{ width: '100%' }} layout={'vertical'} fields={fields}>
        <Row className={styles.row}>
          <Form.Item
            className={styles.padding}
            label={'Страна регистрации бизнеса'}
            style={{ paddingRight: 30 }}
          >
            <RectangleInput
              disabled={true}
              value={(clientState.editedClient?.registrationCountry as any)?.children}
            />
          </Form.Item>
          <Form.Item label={'Организационно-правовая форма'}>
            <RectangleInput
              disabled={true}
              value={LegalForm[clientState.editedClient?.legalForm as keyof typeof LegalForm]}
            />
          </Form.Item>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={12}>
            <Form.Item label={'Название компании'} name={'name'} style={{ width: '100%' }}>
              <RectangleInput disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Form.Item label={'Дата регистрации'} name={'establishedAt'} className={styles.padding}>
            <RectangleInput className={styles.inputPhone} disabled={true} maxLength={12} />
          </Form.Item>
          <Form.Item label={'ИНН'} name={'inn'} className={styles.padding} requiredMark={true}>
            <RectangleInput className={styles.inputPhone} disabled={true} maxLength={12} />
          </Form.Item>
        </Row>
        <Row className={styles.row}>
          <Form.Item label={'КПП:'} name={'kpp'} className={styles.padding} requiredMark={true}>
            <RectangleInput className={styles.inputPhone} disabled={true} maxLength={12} />
          </Form.Item>
          <Form.Item
            label={'Код ОКВЭД:'}
            name={'okved'}
            className={styles.padding}
            requiredMark={true}
          >
            <RectangleInput className={styles.inputPhone} disabled={true} maxLength={12} />
          </Form.Item>
        </Row>
        <Row className={styles.row}>
          <Form.Item
            label={'Код ОКПО:'}
            name={'okpo'}
            className={styles.padding}
            requiredMark={true}
          >
            <RectangleInput className={styles.inputPhone} disabled={true} maxLength={12} />
          </Form.Item>
          <Form.Item
            label={'Код ОКАТО:'}
            name={'okved'}
            className={styles.padding}
            requiredMark={true}
          >
            <RectangleInput className={styles.inputPhone} disabled={true} maxLength={12} />
          </Form.Item>
        </Row>
        <Row className={styles.row}>
          <Form.Item label={'Директор:'} className={styles.padding} requiredMark={true}>
            <RectangleInput
              className={styles.inputPhone}
              disabled={true}
              maxLength={12}
              value={clientState.editedClient?.director?.fio}
            />
          </Form.Item>
          <Form.Item label={'Главный бухгалтер:'} className={styles.padding} requiredMark={true}>
            <RectangleInput
              value={clientState.editedClient?.accountant?.fio}
              className={styles.inputPhone}
              disabled={true}
              maxLength={12}
            />
          </Form.Item>
          <Row className={styles.row}>
            <Form.Item label={'Казначей:'} className={styles.padding} requiredMark={true}>
              <RectangleInput
                value={clientState.editedClient?.treasurer?.fio}
                className={styles.inputPhone}
                disabled={true}
                maxLength={12}
              />
            </Form.Item>
          </Row>
        </Row>
      </Form>
    </>
  );
});
