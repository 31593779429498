import { makeAutoObservable, runInAction } from 'mobx';
import { ClientProvider } from '../../utils/apiClient/clientProvider';
import { notification } from 'antd';
import { ClientTariff } from '../../types/company/client-tariff';
import { System } from '../../types/system';
import { plainToInstance } from 'class-transformer';

class SystemState {
  loading = false;
  data?: System;
  updatedResponse: any;

  constructor() {
    makeAutoObservable(this);
  }

  async load() {
    try {
      this.loading = true;
      runInAction(async () => {
        this.data = plainToInstance(
          System,
          (await ClientProvider.authorized.get(`api/system`)).data,
        );
      });
      this.loading = false;
    } catch (e) {
      console.log(e);
      this.loading = false;
      notification.error({
        message: (e as any).response?.data?.message || (e as any).message,
      });
    }
  }

  async updateTariff() {
    try {
      this.loading = true;
      this.updatedResponse = await ClientProvider.authorized.patch(`api/system/tariff`, {
        defaultTariffPrice: this.data?.defaultTariffPrice,
        defaultTariffPricePerUser: this.data?.defaultTariffPricePerUser,
      });
      this.loading = false;
    } catch (e) {
      console.log(e);
      this.loading = false;
      notification.error({
        message: (e as any).response?.data?.message || (e as any).message,
      });
    }
  }
}

export const systemState = new SystemState();
