import { makeAutoObservable, runInAction } from 'mobx';
import { ClientProvider } from '../../utils/apiClient/clientProvider';
import { notification } from 'antd';
import { Client } from '../../types/company/client';

class ClientsListState {
  loading = false;
  clientsResponse?: ClientsResponseDto;
  currentPage = 1;

  constructor() {
    makeAutoObservable(this);
  }

  async getClientsList(page: number, search: string, sort: any) {
    const take = 10;
    try {
      this.loading = true;
      this.currentPage = page;
      const res = await ClientProvider.authorized.get<ClientsResponseDto>(`api/company`, {
        params: {
          page,
          take,
          search,
          sort: Object.keys(sort).length
            ? `${sort.columnKey}:${sort.order === 'ascend' ? 'asc' : 'desc'}`
            : undefined,
        },
      });
      runInAction(() => {
        this.clientsResponse = res.data as ClientsResponseDto;
      });
      this.loading = false;
    } catch (e) {
      console.log(e);
      this.loading = false;
      notification.error({
        message: (e as any).response?.data?.message || (e as any).message,
      });
    }
  }

  async deleteRestore(companyId: string, isDeleting: boolean) {
    try {
      this.loading = true;
      const res = await ClientProvider.authorized.put<ClientsResponseDto>(
        `api/company/delete/${companyId}`,
        {
          isDeleting,
        },
      );
      this.loading = false;
    } catch (e) {
      this.loading = false;
      notification.error({
        message: (e as any).response?.data?.message || (e as any).message,
      });
    }
  }
}

interface ClientsResponseDto {
  count: number;
  data: Client[];
}

export const clientsListState: ClientsListState = new ClientsListState();
