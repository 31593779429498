import { useState } from 'react';
import { User } from '../types/user';

let role: string;
let user: User | undefined;

export const useCurrentRole = (): {
  currentRole: string;
  setCurrentRole: (r: string) => void;
  isAdmin: boolean;
} => {
  const [currentRole, setCurrentRole] = useState<string>(role);

  return {
    currentRole,
    setCurrentRole: (r: string) => {
      role = r;
      setCurrentRole(r);
    },
    isAdmin: currentRole === 'admin',
  };
};

export const useCurrentUser = (): {
  currentUser: User | undefined;
  setCurrentUser: (u: User | undefined) => void;
} => {
  const [currentUser, setCurrentUser] = useState(user);

  return {
    currentUser,
    setCurrentUser: (u: User | undefined) => {
      user = u;
      setCurrentUser(u);
    },
  };
};
