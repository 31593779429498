import { makeAutoObservable } from 'mobx';
import { storage } from '../../utils/storage';

class AppState {
  authToken?: string;
  email?: string;

  constructor() {
    makeAutoObservable(this);
    this.setAuthToken(storage.authToken || undefined);
    this.setEmail(storage.email || undefined);
  }

  setAuthToken = (v?: string) => {
    this.authToken = v;
    storage.authToken = v || null;
  };

  setEmail = (v?: string) => {
    this.email = v;
    storage.email = v || null;
  };
}

export const appState: AppState = new AppState();
