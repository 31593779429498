import { makeAutoObservable } from 'mobx';

export class ClientBankAccount {
  constructor() {
    makeAutoObservable(this);
  }

  id!: string;
  userId!: string;
  checkingAccount?: string;
  correspondentAccount?: string;
  bik?: string;
  inn?: string;
  kpp?: string;
  receiver: any;
  receivingPerson?: string;
  bankAddress?: string;
}
