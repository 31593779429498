class StorageService {
  set authToken(v: string | null) {
    localStorage.setItem('authToken', v as unknown as string);
  }
  get authToken(): string | null {
    return localStorage.getItem('authToken');
  }

  set refreshToken(v: string | null) {
    localStorage.setItem('refreshToken', v as unknown as string);
  }
  get refreshToken(): string | null {
    return localStorage.getItem('refreshToken');
  }

  set email(v: string | null) {
    localStorage.setItem('email', v as unknown as string);
  }
  get email(): string | null {
    return localStorage.getItem('email');
  }
}

export const storage = new StorageService();
