import { makeAutoObservable } from 'mobx';

export class ClientContacts {
  constructor() {
    makeAutoObservable(this);
  }

  companyId!: string;
  phone?: string;
  paymentsEmail?: string;
  accountsEmail?: string;
  hrEmail?: string;

  legalIndex?: string;
  legalCountry?: any;
  legalCity?: any;
  legalAddress?: string;
  legalOffice?: string;

  realIndex?: string;
  realCountry?: any;
  realCity: any;
  realAddress?: string;
  realOffice?: string;

  isPostalAddress?: boolean;
}
