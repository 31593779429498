import React, { useEffect } from 'react';
import './App.css';
import LoginPage from './views/login';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { InnerLayout } from './views/layout';
import { ClientsListPage } from './views/clients/list';
import { ClientViewPage } from './views/clients/view';
import { autorun } from 'mobx';
import { appState } from './state/system/app.state';
import { userAuthState } from './state/users/user-auth.state';
import { useCurrentRole, useCurrentUser } from './utils/user-role';
import { systemState } from './state/system/system.state';
import { SystemViewPage } from './views/system/view';

function App() {
  const { currentRole, setCurrentRole } = useCurrentRole();
  const { setCurrentUser } = useCurrentUser();

  useEffect(() => {
    return autorun(() => {
      if (currentRole === undefined) {
        if (appState.authToken && window.location.pathname !== '/login') {
          userAuthState.getAuthUser().then((r) => {
            setCurrentRole(userAuthState.user?.role as string);
            setCurrentUser(userAuthState.user);

            systemState.load();
          });

          setInterval(() => {
            userAuthState.getAuthUser();
          }, 5000 * 60);
        } else {
          setCurrentRole('unauthorized');
        }
      }
    });
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path={'/'}
            element={currentRole === 'unauthorized' ? <Navigate to={'/login'} /> : <InnerLayout />}
          >
            <Route path={'/'} element={<Navigate to={'/clients'} />} />
            <>
              <Route path={'/clients'} element={<ClientsListPage />} />
              <Route path={'/clients/:id'} element={<ClientViewPage />} />
            </>
            <>
              <Route path={'/system'} element={<SystemViewPage />} />
            </>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
