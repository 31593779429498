import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BaseClient } from './baseClient';
import { storage } from '../storage';

export class AuthorizedClient extends BaseClient {
  static onSuccessResponse(res: AxiosResponse): AxiosResponse {
    if (res.status >= 200 && res.status <= 400) {
      return res;
    }

    throw new Error(res.data);
  }

  static onFailedResponse(err: any): any {
    if (!storage.authToken) {
      return Promise.reject(err);
    }

    if (err.response && err.response.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(err);
  }

  static onBeforeRequest(config: AxiosRequestConfig) {
    config.headers!['Authorization'] = `Bearer ${storage.authToken}`;
    return config;
  }
}
