import { User } from '../user';
import { ClientContacts } from './client-contacts';
import { ClientBankAccount } from './client-bank-account';
import { Transform, Type } from 'class-transformer';
import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import { ClientTariff } from './client-tariff';

export class ClientFullInfo {
  constructor() {
    makeAutoObservable(this);
  }

  id!: string;
  @Type(() => User)
  accountant?: User | null; // Главный Бухгалтер
  accountantId?: string | null;
  @Type(() => ClientBankAccount)
  bankAccount!: ClientBankAccount;
  @Type(() => ClientContacts)
  contacts!: ClientContacts;
  name?: string; //
  legalForm?: string; // Организационно-правовая форма
  ogrn?: string; // ОГРН
  inn?: string; // ИНН
  kpp?: string; // КПП
  okato?: string; // ОКАТО
  okfs?: string; // ОКФС
  okopf?: string; // ОКОПФ
  okpo?: string; // ОКПО
  okved?: string; // ОКВЭД
  registration?: string;
  registrationCountry?: string;
  @Type(() => User)
  treasurer?: User | null; // казначей
  treasurerId?: string | null;
  @Type(() => User)
  creator!: User; // под вопросом
  creatorId!: string;
  @Type(() => User)
  director?: User | null; // Генеральный директор
  directorId?: string | null;
  @Type(() => Date)
  @Transform(({ value }) => (value === null ? null : moment(value)), { toClassOnly: true })
  establishedAt?: Date;
  vat?: string; // VAT номер
  @Type(() => ClientTariff)
  tariffs!: ClientTariff[];
  @Type(() => User)
  users!: User[];
}

export enum LegalForm {
  ooo = 'Общество с ограниченной ответственностью',
  oao = 'Открытое акционерное общество',
  ip = 'Индивидуальный предприниматель',
  zao = 'Закрытое акционерное общество',
  pao = 'Публичное акционерное общество',
  foreign = 'Иностранная организация',
}
