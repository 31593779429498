import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { tariffState } from '../../../../state/tariffs/tariff.state';
import { ClientTariff } from '../../../../types/company/client-tariff';
import { autorun, get, set } from 'mobx';
import { Card, Form, message, Row, Typography } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import styles from '../../../clients/view/index.module.css';
import { RectangleInput } from '../../../common/rectangle_input';
import { RectangleButton } from '../../../common/rectangle_button';
import { systemState } from '../../../../state/system/system.state';

export const SystemTariffs = observer(() => {
  const fields: FieldData[] = ['defaultTariffPrice', 'defaultTariffPricePerUser'].map((e) => ({
    name: e,
    value: get(systemState.data as any, e),
  }));

  return (
    <Card className={styles.card}>
      <Typography.Text className={styles.titleText}>Редактирование цен тарифов</Typography.Text>(
      <Form
        style={{ width: '100%' }}
        layout={'vertical'}
        onFinish={async () => {
          await systemState.updateTariff();
          await systemState.load();
        }}
        fields={fields}
        validateTrigger={'onSubmit'}
        onFinishFailed={(e) => console.log('finishfail', e)}
        onFieldsChange={(f) => {
          const [v] = f;
          const name = (v.name as any[])[0];
          set((systemState as any).data, name, v.value);
        }}
      >
        <Form.Item
          label={'Базовая цена'}
          name={'defaultTariffPrice'}
          className={styles.padding}
          rules={[{ required: true, message: 'Укажите цену', validateTrigger: 'onSubmit' }]}
        >
          <RectangleInput autoComplete={'off'} placeholder={'Цена'} />
        </Form.Item>
        <Form.Item
          label={'Базовая цена за пользователя'}
          name={'defaultTariffPricePerUser'}
          className={styles.padding}
          rules={[
            {
              required: true,
              message: 'Укажите цену за пользователя',
              validateTrigger: 'onSubmit',
            },
          ]}
        >
          <RectangleInput autoComplete={'off'} placeholder={'Цена за пользователя'} />
        </Form.Item>
        <Row>
          <RectangleButton disabled={tariffState.loading} htmlType="submit">
            Сохранить
          </RectangleButton>
        </Row>
      </Form>
      )
    </Card>
  );
});
