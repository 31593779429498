import React, { useEffect } from 'react';
import { Button, Card, Form } from 'antd';
import { observer } from 'mobx-react';
import { loginState } from '../../state/login/login.state';
import { autorun } from 'mobx';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.css';
import { RectangleInput } from '../common/rectangle_input';
import { appState } from '../../state/system/app.state';
import Lottie from 'lottie-react';
import loginAnim from '../lottie/admin.json';

const LoginPage = observer(() => {
  const history = useNavigate();
  const style = {
    height: 300,
  };
  useEffect(() => {
    return autorun(() => {
      if (loginState.loginResponse) {
        appState.setAuthToken(loginState.loginResponse.accessToken);
        window.location.href = '/';
      }
    });
  }, [history]);

  return (
    <>
      <Form className={styles.form} layout="vertical" onFinish={() => loginState.tryLogin()}>
        <Card className={styles.card} bordered={true}>
          <Lottie animationData={loginAnim} style={style} loop={true} />
          <span className={styles.title}>Вход</span>
          <Form.Item
            label={'E-mail'}
            name={'email'}
            requiredMark={'optional'}
            rules={[{ required: true, message: 'Укажите e-mail' }]}
            className={styles.item}
          >
            <RectangleInput
              width={292}
              height={40}
              type="email"
              value={loginState.email}
              onChange={(e: any) => (loginState.email = e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={'Пароль'}
            className={styles.item}
            name={'password'}
            requiredMark={'optional'}
            rules={[{ required: true, message: 'Укажите пароль' }]}
          >
            <RectangleInput
              type="password"
              value={loginState.password}
              minLength={8}
              onChange={(e: any) => (loginState.password = e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.button}
              type="primary"
              color={'#24CC7F'}
              htmlType="submit"
              loading={loginState.loading}
            >
              Вход
            </Button>
          </Form.Item>
          <div className={styles.line} />
        </Card>
      </Form>
    </>
  );
});

export default LoginPage;
