import { makeAutoObservable, runInAction } from 'mobx';
import { ClientProvider } from '../../utils/apiClient/clientProvider';
import { notification } from 'antd';

class LoginState {
  private _email = '';
  private _password = '';
  private _loginResponse?: LoginResponseDto;
  private _loading = false;

  get loading(): boolean {
    return this._loading;
  }

  get loginResponse(): LoginResponseDto | undefined {
    return this._loginResponse;
  }

  set loginResponse(value) {
    this._loginResponse = value;
  }

  get password(): string {
    return this._password;
  }

  set password(value: string) {
    this._password = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  constructor() {
    makeAutoObservable(this);
  }

  async tryLogin() {
    try {
      this._loading = true;
      const res = await ClientProvider.default.post<LoginResponseDto>('api/auth/login', {
        login: this.email,
        password: this.password,
      });
      runInAction(() => {
        this.loginResponse = res.data as LoginResponseDto;
      });
      this._loading = false;
    } catch (e) {
      console.log(e);
      this._loading = false;
      notification.error({
        message: (e as any).response?.data?.message || (e as any).message,
      });
    }
  }
}

interface LoginResponseDto {
  accessToken: string;
}

export const loginState: LoginState = new LoginState();
