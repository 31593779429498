import { Button, Card, Col, Image, Popconfirm, Row, Table, Typography } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import { clientsListState } from '../../../state/clients/clients-list.state';
import styles from './index.module.css';
import { RectangleInput } from '../../common/rectangle_input';
import moment from 'moment';
import { IconButton } from '../../common/icon-button';
import { DeleteFilled, ReloadOutlined, EyeFilled } from '@ant-design/icons';

const { Column } = Table;

export const ClientsListPage = observer(() => {
  const history = useNavigate();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState({} as any);

  useEffect(() => {
    clientsListState.getClientsList(clientsListState.currentPage, search, sort);
  }, [history]);

  const handleTableChange = (pagination: any, filters: any, sort: any) => {
    setSort(sort);
    clientsListState.currentPage = pagination.current;
    clientsListState.getClientsList(clientsListState.currentPage, search, sort);
  };

  const renderActions = (_: any, item: any) => {
    const isDeleted = !!item.deletedAt;
    return (
      <Row>
        <Link to={`/clients/${item.id}`}>
          <IconButton icon={<EyeFilled style={{ color: '#24CC7F' }} />} />
        </Link>
        <Popconfirm
          title={isDeleted ? 'Восстановить компанию' : 'Удалить компанию?'}
          onConfirm={async () => {
            await clientsListState.deleteRestore(item.id, !isDeleted);
            clientsListState.getClientsList(clientsListState.currentPage, search, sort);
          }}
        >
          <IconButton
            icon={
              isDeleted ? (
                <ReloadOutlined style={{ color: '#24CC7F' }} />
              ) : (
                <DeleteFilled style={{ color: '#FF7171' }} />
              )
            }
          />
        </Popconfirm>
      </Row>
    );
  };

  const renderStatus = (item: any): JSX.Element => {
    const isActive: string = item.deletedAt ? null : item.isActive;
    const color = {
      true: '#24CC7F',
      false: '#F3A42D',
      null: '#FF7171',
    }[isActive];

    const name = {
      true: 'Активный',
      false: 'Неактивный',
      null: 'Удалён',
    }[isActive];

    return <span style={{ color }}>{name}</span>;
  };

  const renderEmail = (value: any, item: any) => {
    return <span>{item.contacts.paymentsEmail}</span>;
  };

  const renderPhone = (value: any, item: any) => {
    return <span>{item.contacts.phone}</span>;
  };

  const renderDate = (value: any, item: any) => {
    return <span>{moment(value).format('DD.MM.YYYY')}</span>;
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    clientsListState.getClientsList(clientsListState.currentPage, e.target.value, sort);
  };

  return (
    <>
      <Card className={styles.card}>
        <Col>
          <Typography.Text className={styles.text}>Выберите клиента</Typography.Text>
          <div style={{ height: 10 }} />
          <Row style={{ justifyContent: 'space-between' }}>
            <RectangleInput
              suffix={
                <Image preview={false} height={20} width={17} src="/assets/images/search.svg" />
              }
              value={search}
              onChange={onChangeSearch}
            />
          </Row>
          <br />
          <Table
            dataSource={clientsListState.clientsResponse?.data}
            pagination={{
              current: clientsListState.currentPage,
              pageSize: 10,
              total: clientsListState.clientsResponse?.count,
              showSizeChanger: false,
            }}
            onChange={handleTableChange}
            rowKey={'id'}
          >
            <Column
              title="Название компании"
              dataIndex="name"
              sorter={true}
              key="name"
              width={200}
            />
            <Column
              title="E-mail"
              dataIndex="contacts.paymentsEmail"
              sorter={false}
              render={renderEmail}
              key="contacts.paymentsEmail"
              width={200}
            />
            <Column
              title="Телефон"
              dataIndex="contacts.phone"
              sorter={false}
              key="contacts.phone"
              render={renderPhone}
              width={160}
            />
            <Column
              title="Дата регистрации"
              dataIndex="createdAt"
              sorter={true}
              key="createdAt"
              render={renderDate}
              width={130}
            />
            <Column title="Статус" render={renderStatus} sorter={false} key="status" width={130} />
            <Column
              title="Действия"
              dataIndex="actions"
              key="actions"
              width={150}
              render={renderActions}
            />
          </Table>
        </Col>
      </Card>
    </>
  );
});
