import { makeAutoObservable, runInAction } from 'mobx';
import { ClientProvider } from '../../utils/apiClient/clientProvider';
import { notification } from 'antd';
import { plainToInstance } from 'class-transformer';
import { ClientFullInfo } from '../../types/company/client-full';
import { ClientServicePaymentViewable } from '../../types/company/client-tariff';
import moment from 'moment';

class ClientState {
  loading = false;
  editedClient?: ClientFullInfo;
  payments: ClientServicePaymentViewable[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async byId(id: string) {
    try {
      this.loading = true;
      const res = await ClientProvider.authorized.get(`api/company/${id}`);
      runInAction(() => {
        this.editedClient = plainToInstance(ClientFullInfo, res.data);
        this.mapPayments(this.editedClient);
      });
      this.loading = false;
    } catch (e) {
      console.log(e);
      this.loading = false;
      notification.error({
        message: (e as any).response?.data?.message || (e as any).message,
      });
    }
  }

  mapPayments(company: ClientFullInfo) {
    if (!company.tariffs) return;
    const payments = company.tariffs.reduce<ClientServicePaymentViewable[]>((a, x) => {
      return [
        ...a,
        ...x.servicePeriods.map((period, i) => {
          const payment = period.payment;
          const paymentMethod = x?.paymentMethod?.card?.first6
            ? x?.cardFormatted
            : x?.yooFormatted || 'Нет';
          return {
            paymentMethod: paymentMethod,
            period: `${moment(period.startsAt).format('DD.MM.YYYY')} - ${moment(
              period.endsAt,
            ).format('DD.MM.YYYY')}`,
            userCount: period.employeeIncrement,
            sum: payment?.amount?.value || 'Нет',
          } as ClientServicePaymentViewable;
        }),
      ];
    }, []);
    this.payments = payments.map((e, i) => ({
      ...e,
      idx: payments.length - i,
    }));
  }

  async deleteUser(userId: string) {
    try {
      this.loading = true;
      await ClientProvider.authorized.delete(`api/user/${userId}`);
    } finally {
      this.byId(this.editedClient!.id);
    }
  }
}

export const clientState = new ClientState();
