import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Card, Tabs, Typography } from 'antd';
import styles from './index.module.css';
import { Employees } from '../components/tabs/employees';
import { CompanyViewInfo } from '../components/tabs/company-view';
import { clientState } from '../../../state/clients/client.state';
import { Loader } from '../../common/loader';
import { Payments } from '../components/tabs/payments';

export const ClientViewPage = observer(() => {
  const { id } = useParams() as {
    id: string;
  };

  useEffect(() => {
    clientState.byId(id);
  }, [id]);

  if (!clientState.editedClient) {
    return <Loader />;
  }

  return (
    <>
      <Card className={styles.card}>
        <div className={styles.column}>
          <Typography.Text className={styles.textCompanyTitle}>Карточка компании</Typography.Text>
          <Tabs>
            <Tabs.TabPane tab="Карточка компании" key="1">
              <CompanyViewInfo />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Список сотрудников" key="2">
              <Employees />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Платежи" key="3">
              <Payments />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Card>
    </>
  );
});
