import { observer } from 'mobx-react';
import React from 'react';
import { Card, Tabs, Typography } from 'antd';
import styles from './index.module.css';
import { Loader } from '../../common/loader';
import { systemState } from '../../../state/system/system.state';
import { SystemTariffs } from '../components/tabs/tariffs';

export const SystemViewPage = observer(() => {
  const reload = () => {
    systemState.load();
  };

  if (!systemState.data) {
    return <Loader />;
  }

  return (
    <>
      <Card className={styles.card}>
        <div className={styles.column}>
          <Typography.Text className={styles.textCompanyTitle}>Системные настройки</Typography.Text>
          <Tabs>
            <Tabs.TabPane tab="Тарифы" key="1">
              <SystemTariffs />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Card>
    </>
  );
});
