import { observer } from 'mobx-react';
import { Table, Typography } from 'antd';
import styles from '../../view/index.module.css';
import React from 'react';
import moment from 'moment';
import Column from 'antd/es/table/Column';
import { clientState } from '../../../../state/clients/client.state';
import { User } from '../../../../types/user';
import { IconButton } from '../../../common/icon-button';
import { DeleteFilled } from '@ant-design/icons';

export const Employees = observer(() => {
  const renderCreatedAt = (value: any, record: any) => {
    return <span>{moment(value).format('DD.MM.YYYY HH:mm:ss')}</span>;
  };

  const renderFiredAt = (value: any, record: any) => {
    const fires = record.firesReceived || [];
    if (!fires.length || fires[0].restoredAt) {
      return null;
    }
    return <span>{moment(fires[0].firedAt).format('DD.MM.YYYY HH:mm:ss')}</span>;
  };

  const renderFio = (value: any, record: any, index: number) => {
    return <span>{record.fio}</span>;
  };

  const renderStatus = (value: any, item: any) => {
    let status = 'Активный';
    if (item.isFired) {
      status = 'Уволен';
    }
    if (!item.isVerified) {
      status = 'Неподтвержденный';
    }

    return <span>{status}</span>;
  };

  const renderWorkInfo = (_: any, item: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const val = {
      contract: 'Трудовой договор',
      ip: 'ИП',
      self: 'Самозанятый',
    }[item?.workInfos?.[0]?.hireType];

    return <span>{val}</span>;
  };

  const renderActions = (user: User) => {
    const _delete = () => {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Удаление пользователя необратимо')) {
        clientState.deleteUser(user.id!);
      }
    };

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {!user.isVerified && (
          <IconButton
            color={'red'}
            onClick={_delete}
            icon={<DeleteFilled style={{ color: '#24CC7F' }} />}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <p>
        <Typography.Text className={styles.titleText}>Сотрудники</Typography.Text>
      </p>
      {clientState.editedClient?.users ? (
        <Table dataSource={clientState.editedClient?.users} rowKey={'id'}>
          <Column title="ФИО" dataIndex="fio" key="fio" width={250} render={renderFio} />
          <Column title="EMAIL" dataIndex="workEmail" key="workEmail" width={200} />
          <Column
            title="Дата регистрации"
            dataIndex="createdAt"
            key="createdAt"
            render={renderCreatedAt}
          />
          <Column title="Статус" dataIndex="status" key="status" render={renderStatus} />
          <Column
            title="Тип найма"
            dataIndex="workInfos[0].hireType"
            key="hireType"
            render={renderWorkInfo}
          />
          <Column
            title="Дата увольнения"
            dataIndex="workInfos.firedAt"
            key="workInfos.firedAt"
            render={renderFiredAt}
          />
          <Column title="Действия" key="actions" render={renderActions} />
        </Table>
      ) : (
        <p className={styles.emptyTable}>Нет сотрудников</p>
      )}
    </>
  );
});
