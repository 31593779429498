import { makeAutoObservable, runInAction } from 'mobx';
import { ClientProvider } from '../../utils/apiClient/clientProvider';
import { notification } from 'antd';
import { plainToInstance } from 'class-transformer';
import { User } from '../../types/user';

class UserAuthState {
  user?: any;
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (v: boolean) => (this.loading = v);

  async getAuthUser() {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.get('api/user/me');
      if (!res.data.errors) {
        runInAction(() => {
          this.user = plainToInstance(User, res.data);
        });
      } else {
        notification.error({
          message: (res as any).response?.data?.message || (res as any).message,
        });
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  get fullName(): string | undefined {
    return `${this.user?.name} ${this.user?.surname}`;
  }
}
export const userAuthState: UserAuthState = new UserAuthState();
