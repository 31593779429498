import { Dropdown, Image, Layout, Menu, Progress, Row, Spin, Steps, Typography } from 'antd';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import styles from './index.module.css';
import React from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { MenuInfo } from 'rc-menu/lib/interface';
import { Observer } from 'mobx-react';

import { Loader } from '../common/loader';
import { Content } from 'antd/es/layout/layout';

const { Header, Sider } = Layout;

export const InnerLayout = () => {
  const history = useLocation();
  const route = useNavigate();

  const getDefaultSelectedKeys = () => {
    const p = history.pathname;
    if (p === '/clients') {
      return ['clients'];
    }
    if (p === '/system') {
      return ['system'];
    }
  };

  const onExit = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  return (
    <Layout>
      <Header className={styles.header}>
        <Typography.Title className={styles.headerText} level={3} onClick={() => route('/')}>
          Admin panel
        </Typography.Title>
        <Row style={{ justifyContent: 'flex-end' }}>
          <Typography.Text onClick={onExit} style={{ cursor: 'pointer' }}>
            Выход
          </Typography.Text>
        </Row>
      </Header>
      <Layout style={{ height: '100vh' }}>
        <Sider
          style={{
            background: '#ffffff',
            overflow: 'auto',
            position: 'fixed',
            left: 0,
            top: '7.5vh',
            bottom: 0,
            overflowX: 'hidden',
          }}
          width={260}
        >
          <div>
            <Observer>
              {() => (
                <Menu mode="inline" theme="light" defaultSelectedKeys={getDefaultSelectedKeys()}>
                  <Menu.Divider />
                  <Menu.Item key="clients">
                    <Link to={'/clients'}>
                      <Typography.Text className={styles.menuItem}>Клиенты</Typography.Text>
                    </Link>
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item key="system">
                    <Link to={'/system'}>
                      <Typography.Text className={styles.menuItem}>Система</Typography.Text>
                    </Link>
                  </Menu.Item>
                  <Menu.Divider />
                </Menu>
              )}
            </Observer>
          </div>
        </Sider>
        <Content className={styles.content}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
