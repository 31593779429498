import { DefaultClient } from './defaultClient';
import { AuthorizedClient } from './authorizedClient';

export const apiUrl = process.env.REACT_APP_API_URL || '';
export const fileUrl = process.env.REACT_APP_FILE_URL;

const defaultClient: DefaultClient = new DefaultClient(apiUrl);
const authorizedClient: AuthorizedClient = new AuthorizedClient(apiUrl);
// example of interceptor
defaultClient.setResponseInterceptors(DefaultClient.onSuccessResponse);
authorizedClient.setResponseInterceptors(
  AuthorizedClient.onSuccessResponse,
  AuthorizedClient.onFailedResponse,
);
authorizedClient.setRequestInterceptors(AuthorizedClient.onBeforeRequest);

export const ClientProvider = {
  default: defaultClient,
  authorized: authorizedClient,
};
