import { observer } from 'mobx-react';
import { Button, Card, Form, message, Popconfirm, Row, Table, Typography } from 'antd';
import styles from '../../view/index.module.css';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Column from 'antd/es/table/Column';
import { clientState } from '../../../../state/clients/client.state';
import { RectangleButton } from '../../../common/rectangle_button';
import { DeleteFilled } from '@ant-design/icons';
import { IconButton } from '../../../common/icon-button';
import { autorun, get, set } from 'mobx';
import { FieldData } from 'rc-field-form/lib/interface';
import { tariffState } from '../../../../state/tariffs/tariff.state';
import { ClientTariff } from '../../../../types/company/client-tariff';
import { RectangleInput } from '../../../common/rectangle_input';
import { systemState } from '../../../../state/system/system.state';

export const Payments = observer(() => {
  const [addingTariff, setAddingTariff] = useState(false);

  const renderPeriod = (_: any, item: any) => {
    const end = item.disabledAt ? moment(item.disabledAt).format('DD.MM.YYYY') : 'Нет даты';
    const start = item.activatedAt
      ? moment(item.activatedAt).format('DD.MM.YYYY')
      : moment(item.scheduleToActivateAt).format('DD.MM.YYYY') + ' (Запланировано)';
    return (
      <span>
        {start} / {end}
      </span>
    );
  };

  const renderTest = (value: boolean) => <span>{value ? 'Да' : 'Нет'}</span>;

  const renderActions = (_: any, item: any) => {
    const now = moment();
    if (!item.disabledAt && moment(item.activatedAt).isBefore(now)) {
      return null;
    }
    return (
      <Popconfirm
        title={'Удалить тариф?'}
        onConfirm={async () => {
          await tariffState.delete(item.id);
          clientState.byId(clientState.editedClient!.id);
        }}
      >
        <IconButton icon={<DeleteFilled style={{ color: '#FF7171' }} />} />
      </Popconfirm>
    );
  };

  if (addingTariff) {
    return (
      <TariffAdd
        onSuccess={() => {
          setAddingTariff(false);
          clientState.byId(clientState.editedClient!.id);
        }}
      />
    );
  }

  return (
    <>
      <p>
        <Row justify={'center'}>
          <Typography.Text className={styles.titleText}>Тарифы</Typography.Text>
          <RectangleButton
            onClick={() => setAddingTariff(true)}
            style={{ position: 'absolute', right: 0 }}
          >
            Добавить
          </RectangleButton>
        </Row>
      </p>
      <br />
      {clientState.editedClient?.tariffs ? (
        <Table dataSource={clientState.editedClient?.tariffs} rowKey={'id'} pagination={false}>
          <Column
            title="Дата активации/деактивации"
            dataIndex="period"
            key="period"
            render={renderPeriod}
            width={300}
          />
          <Column title="Цена" dataIndex="price" key="price" width={100} />
          <Column
            title="Цена за пользователя"
            dataIndex="perUserPrice"
            key="perUserPrice"
            width={200}
          />
          <Column
            title="Тестовый период"
            dataIndex="isTest"
            key="isTest"
            width={100}
            render={renderTest}
          />
          <Column
            title="Действия"
            dataIndex="actions"
            key="actions"
            width={100}
            render={renderActions}
          />
        </Table>
      ) : (
        <p className={styles.emptyTable}>Нет платежей</p>
      )}
      <br />
      <p>
        <Typography.Text className={styles.titleText}>Платежи</Typography.Text>
      </p>
      {clientState.payments ? (
        <Table dataSource={clientState.payments} rowKey={'id'} pagination={false}>
          <Column title="№" dataIndex="idx" key="idx" width={200} />
          <Column title="Период списания" dataIndex="period" key="period" width={200} />
          <Column title="Кол-во человек" dataIndex="userCount" key="userCount" width={200} />
          <Column title="Сумма" dataIndex="sum" key="sum" width={200} />
        </Table>
      ) : (
        <p className={styles.emptyTable}>Нет платежей</p>
      )}
    </>
  );
});

export const TariffAdd = observer(({ onSuccess }: { onSuccess: () => void }) => {
  useEffect(() => {
    tariffState.addedTariff = new ClientTariff();

    return autorun(() => {
      if (tariffState.updatedResponse !== undefined) {
        message.success('Информация о тарифе добавлена');
        onSuccess();
      }
    });
  }, []);

  if (!tariffState.addedTariff || !systemState.data) {
    return null;
  }

  const fields: FieldData[] = ['price', 'perUserPrice'].map((e) => ({
    name: e,
    value: get(tariffState.addedTariff as any, e),
  }));

  return (
    <Card className={styles.card}>
      <Typography.Text className={styles.titleText}>Создание тарифа</Typography.Text>(
      <Form
        style={{ width: '100%', marginTop: '30px' }}
        layout={'vertical'}
        onFinish={async () => {
          await tariffState.create(clientState.editedClient!.id);
        }}
        fields={fields}
        validateTrigger={'onSubmit'}
        onFinishFailed={(e) => console.log('finishfail', e)}
        onFieldsChange={(f) => {
          const [v] = f;
          const name = (v.name as any[])[0];
          set((tariffState as any).addedTariff, name, v.value);
        }}
      >
        <Form.Item
          label={'Цена'}
          name={'price'}
          className={styles.padding}
          rules={[{ required: true, message: 'Укажите цену', validateTrigger: 'onSubmit' }]}
        >
          <RectangleInput
            autoComplete={'off'}
            placeholder={'Цена'}
            defaultValue={systemState.data.defaultTariffPrice}
          />
        </Form.Item>
        <Form.Item
          label={'Цена за пользователя'}
          name={'perUserPrice'}
          className={styles.padding}
          rules={[
            {
              required: true,
              message: 'Укажите цену за пользователя',
              validateTrigger: 'onSubmit',
            },
          ]}
        >
          <RectangleInput
            autoComplete={'off'}
            placeholder={'Цена за пользователя'}
            defaultValue={systemState.data.defaultTariffPricePerUser}
          />
        </Form.Item>
        <Row>
          <RectangleButton disabled={tariffState.loading} htmlType="submit">
            Сохранить
          </RectangleButton>
          <Button className={styles.cancel} type="ghost" onClick={onSuccess} htmlType="reset">
            Отмена
          </Button>
        </Row>
      </Form>
      )
    </Card>
  );
});
