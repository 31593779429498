import { AxiosResponse } from 'axios';
import { BaseClient } from './baseClient';

export class DefaultClient extends BaseClient {
  static onSuccessResponse(res: AxiosResponse): AxiosResponse {
    if (res.status >= 200 && res.status <= 400) {
      return res;
    }

    throw new Error(res.data);
  }
}
