import { observer } from 'mobx-react';
import { Image, Input, InputNumber, InputNumberProps, InputProps } from 'antd';
import React from 'react';
import styles from './index.module.css';

export const RectangleInput = observer((props: InputProps) => {
  return (
    <>
      {props.type === 'password' ? (
        <Input.Password
          className={styles.input}
          iconRender={(visible) =>
            visible ? (
              <Image
                className={styles.icon}
                preview={false}
                height={22}
                width={16}
                src="/assets/images/eye.svg"
              />
            ) : (
              <Image
                className={styles.icon}
                preview={false}
                height={22}
                width={16}
                src="/assets/images/eye_close.svg"
              />
            )
          }
          {...props}
        />
      ) : (
        <Input className={styles.input} {...props} />
      )}
    </>
  );
});

export const RectangleNumberInput = observer((props: InputNumberProps) => {
  return (
    <>
      <InputNumber className={styles.input} {...props} />
    </>
  );
});
