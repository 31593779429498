import { User } from '../user';
import { Transform, Type } from 'class-transformer';
import { makeAutoObservable } from 'mobx';
import moment from 'moment';

export class ClientTariff {
  constructor() {
    makeAutoObservable(this);
  }

  id!: string;
  @Type(() => User)
  accountant?: User; // Главный Бухгалтер

  perUserPrice!: number;
  price!: number;

  @Type(() => Date)
  @Transform(({ value }) => (value === null ? value : moment(value)), { toClassOnly: true })
  activatedAt?: Date;

  @Type(() => Date)
  @Transform(({ value }) => moment(value), { toClassOnly: true })
  createdAt!: Date;

  @Type(() => Date)
  @Transform(({ value }) => (value === null ? value : moment(value)), { toClassOnly: true })
  disabledAt!: Date | null;

  isTest!: boolean;
  paymentMethod: any | null;
  @Type(() => ClientServicePeriod)
  servicePeriods!: ClientServicePeriod[];

  get cardFormatted() {
    if (!this.paymentMethod?.card) {
      return '';
    }
    const slice1 = this.paymentMethod.card.first6.slice(0, 4);
    const slice2 = this.paymentMethod.card.first6.slice(4, 6);
    return `${slice1} ${slice2}xx xxxx ${this.paymentMethod.card.last4}`;
  }

  get yooFormatted() {
    if (this.paymentMethod?.type !== 'yoo_money') {
      return '';
    }

    return this.paymentMethod?.title;
  }
}

export class ClientServicePeriod {
  id!: string;
  tariffId?: string;
  tariff?: ClientTariff;
  employeeIncrement!: number;
  employeeCountCurrent!: number;
  lockPaymentsUntil?: Date;

  startsAt?: Date;
  endsAt?: Date;
  payment!: ClientServicePayment;
}

export class ClientServicePayment {
  id!: string;
  amount: any | null;
  confirmation: any | null;

  @Type(() => Date)
  @Transform(({ value }) => moment(value), { toClassOnly: true })
  createdAt!: Date;

  description!: string;

  @Type(() => Date)
  @Transform(({ value }) => (value === null ? value : moment(value)), { toClassOnly: true })
  expiresAt!: Date | null;

  incomeAmount: any | null;
  isBindingPayment!: boolean;
  metadata: any | null;

  @Type(() => Date)
  @Transform(({ value }) => (value === null ? value : moment(value)), { toClassOnly: true })
  periodEndsAt!: Date | null;

  @Type(() => Date)
  @Transform(({ value }) => (value === null ? value : moment(value)), { toClassOnly: true })
  periodStartsAt!: Date | null;

  refundable!: boolean;
  status!: string;
  test!: boolean;
}

export class ClientServicePaymentViewable {
  paymentMethod!: string;
  period!: string;
  sum!: string;
}
