import { makeAutoObservable } from 'mobx';

export class System {
  constructor() {
    makeAutoObservable(this);
  }

  id?: string;
  defaultTariffPricePerUser!: string;
  defaultTariffPrice!: string;
}
