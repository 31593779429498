import { makeAutoObservable } from 'mobx';
import { Transform, Type } from 'class-transformer';
import moment from 'moment';
import { fio } from '../utils/fio';

export class User {
  constructor() {
    makeAutoObservable(this);
  }

  id?: string;
  name?: string;
  surname?: string;
  patronymic?: string;
  @Type(() => Date)
  @Transform(({ value }) => moment(value), { toClassOnly: true })
  dob?: Date | string;
  phone?: string;
  email?: string;
  personalInn?: string;
  workEmail?: string;
  position?: string;
  startedWorkAt?: string;
  gender?: string;
  role?: string; //роль
  password?: string;
  @Type(() => Date)
  @Transform(({ value }) => moment(value), { toClassOnly: true })
  createdAt?: Date | string;
  @Type(() => Date)
  @Transform(({ value }) => moment(value), { toClassOnly: true })
  updatedAt?: Date | string;
  status?: string;
  isVerified?: boolean;

  get fio(): string {
    return fio(this);
  }
}
