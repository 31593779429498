import { Button, ButtonProps } from 'antd';
import React from 'react';
import styles from './index.module.css';

export const RectangleButton = (props: ButtonProps & React.RefAttributes<HTMLElement>) => {
  return (
    <>
      <Button className={styles.button} {...props} />
    </>
  );
};
